/** @jsx jsx */
import { useState, useEffect } from 'react';
import { Box, Text, Button, jsx, Heading } from 'theme-ui';
import { RichText } from 'prismic-reactjs';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import useWindowSize from '../../hooks/useWindowSize';
import styles from './call-us-banner.style';
import { Whatsapp } from '../icons';

const CallusBanner = (props: any) => {
	const windowSize = useWindowSize();
	const [offset, setOffset] = useState(60);
	const {
		callUsBanner,
		callUsTitle,
		callUsButtonText,
		scrollTo = '#feature',
	} = props;
	useEffect(() => {
		if (windowSize < 1081) {
			setOffset(80);
		} else {
			setOffset(60);
		}
	}, [windowSize]);

	return (
		<Box
			as="section"
			className="call-us-banner"
			sx={{
				backgroundImage: `url(${
					callUsBanner && callUsBanner.url ? callUsBanner.url : ''
				})`,
				...styles.banner,
			}}
		>
			<Box as="header" sx={styles.bannerContent}>
				<Heading as="h3">{RichText.render(callUsTitle)}</Heading>
					<Button variant="white" onClick={() => { if (typeof window !== "undefined") {window.open("https://www.amazon.in/events/greatindianfestival/?tag=ncom02-21"); window.gtag("event", "amzref")}}}>
						Shop Now
					</Button>
			</Box>
		</Box>
	);
};

export default CallusBanner;
